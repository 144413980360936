/**
 *
 * @param ele  要生成的 pdf 的DOM元素
 * @param pdfName PDF文件的名称
 */
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const pdfD2 = async (ele, pdfName) => {
  let a = false
  ele.style.overflowY = 'visible'
  ele.style.height = 'auto'
  //
  // let eleW = ele.offsetWidth // 获取容器的宽
  // let eleH = ele.offsetHeight // 获取容器的高
  // let eleOffsetTop = ele.offsetTop // 获取容器到文档顶部的距离
  // let eleOffsetLeft = ele.offsetLeft // 获取容器到文档最左的距离

  // let canvas = document.createElement('canvas')
  // let abs = 0

  // let win_in =
  //   document.documentElement.clientHeight || document.body.clientHeight // 获得当前可视窗口的高度（不包含滚动条）
  // let win_out = window.clientHeight // 获得当前窗口的高度（包含滚动条）
  // let win_out = window.clientHeight // 获得当前窗口的高度（包含滚动条）

  // if (win_out > win_in) {
  // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
  // abs = (win_out - win_in) / 2 // 获得滚动条宽度的一半
  // console.log(a, '新abs');
  // }
  // canvas.width = eleW * 2 // 将画布宽&&高放大两倍
  // canvas.height = eleH * 2

  // let context = canvas.getContext('2d')
  // context.scale(2, 2)
  // context.translate(-eleOffsetLeft - abs, -eleOffsetTop)
  // // 【重要】关闭抗锯齿
  // context.mozImageSmoothingEnabled = false
  // context.webkitImageSmoothingEnabled = false
  // context.msImageSmoothingEnabled = false
  // context.imageSmoothingEnabled = false
  // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
  // translate的时候，要把这个差值去掉
  await html2canvas(ele, {
    scale: 2,
    dpi: 450,
    useCORS: true // 跨域请求
  }).then(canvas => {
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    //一页pdf显示html页面生成的canvas高度;
    let pageHeight = (contentWidth / 592.28) * 841.89
    //未生成pdf的html页面高度
    let leftHeight = contentHeight
    //页面偏移
    let position = 0
    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    // let imgWidth = 595.28
    // let imgHeight = (595.28 / contentWidth) * contentHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)

    // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
    // 2为上面的scale 缩放了2倍
    let pdfX = ((contentWidth + 10) / 2) * 0.75
    let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    //当内容未超过pdf一页显示的范围，无需分页

    //
    let imgX = pdfX
    let imgY = (contentHeight / 2) * 0.75
    let pdf = new JsPDF('', 'pt', [pdfX, pdfY])
    // if (leftHeight < pageHeight) {
    //   //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
    //   pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
    // } else {
    // // 分页
    // while (leftHeight > 0) {
    //   pdf.addImage(pageData, 'JPEG', 0, position, imgX, imgY)
    //   leftHeight -= pageHeight
    //   position -= 841.89
    //   //避免添加空白页
    //   if (leftHeight > 0) {
    //     pdf.addPage()
    //   }
    // }
    // }
    //可动态生成
    pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
    pdf.save(pdfName)
    ele.style.overflowY = ''
    ele.style.height = ''
    a = true
  })
  return a
}
